var megaMenu4i = function(){
	const menuArea = document.querySelector(".headerMenuBtn");
	const topMenu = document.getElementById("topOfMenu");

	// not available until the menu HTML gets added to the dom
	let dropDown, dropDownTrigger, dropDownClose, menuTrigger, menuOverlay;

	let init = () => { 
		fetch('/header/menu2021', { 
    			headers: { 'X-Requested-With': 'fetch' 
    		}}).then(function(response ) {
			return  (response.ok) ? response.text() : '';    		
    		}).then(function(html) {
			if(html.length < 1) return;
			topMenu.innerHTML = html;
			
			//a/b test
			if(!topMenu.querySelector("#kamTestLink")) topMenu.insertAdjacentHTML("afterbegin", getLinkHTML());
		
			menuArea.addEventListener("click", events);
			initVariables();

			menuTrigger.addEventListener('click', open);
			menuOverlay.addEventListener("click", close);
			dropDownClose.addEventListener('click', close);
			
			menuTrigger.click();
			//open(new Event('fakeclick'));
		});
	},
	
	// a/b test
	getLinkHTML = () => {
		return '<li id="kamTestLink" class="hide menuRow"><a class="abUSA bkgdLtGray show noLine darkestLink adobe-tag-tracker" href="/tag/727/usa-made"><span>USA Made</span></a></li>';
	},
	
	initVariables = () => {
		dropDown = document.getElementById('menuDropDown');
		dropDownTrigger = document.querySelector(".cd-dropdown-trigger");
		dropDownClose = dropDown.querySelector(".cd-close");
		menuTrigger = document.getElementById("menuTrigger");
		menuOverlay = document.querySelector(".menuCover");
	},
	findVisible = (el) =>{
		return el.offsetWidth > 0 || el.offsetHeight > 0;
	},
	events = (e) => {	
		let el = e.target;
		if(el.parentNode.classList.contains("has-children")){
			e.preventDefault();
			showSub(el);
		}else if(el.parentNode.classList.contains("go-back")){
			e.preventDefault();
			if(el.parentNode.parentNode.classList.contains("cd-secondary-dropdown")){
				secondBackBtnEvent();
			}
			goBack(el);
		}
		
		if(el.classList.contains("adobe-tag-tracker")) omnitureLog.LogClick(el);
		
		/*
		const hasChildren = document.getElementsByClassName("has-children");
		for(let i = 0; i < hasChildren.length; i++){
			hasChildren[i].querySelector("a").addEventListener("click", showSub);
		}
		const adobeTags = document.getElementsByClassName("adobe-tag-tracker");
		for(let i = 0; i < adobeTags.length; i++){
			adobeTags[i].addEventListener("click", omnitureLog.LogClick);
		}
		const goBackLinks = document.getElementsByClassName("go-back");
		for(let i = 0; i < goBackLinks.length; i++){
			goBackLinks[i].querySelector("button").addEventListener('click', goBack);
		}
		// fix for not the first back buttons in a sub menu but the second one in the sub subs
		const hasChildrenUl = document.getElementsByClassName('cd-secondary-dropdown');
		for(let x = 0; x < hasChildrenUl.length; x++){
			const backBtn = hasChildrenUl[x].querySelector(".go-back").querySelector("button");
			backBtn.addEventListener("click", secondBackBtnEvent);
		}
		*/		
	},
	showSub = (el) => {
		//e.preventDefault();	
		//const el = e.currentTarget;
		el.setAttribute("aria-expanded", "true");
		
		const menuToHide = el.parentNode.parentNode; //<ul>
		menuToHide.classList.add('move-out');
		hidePreviousLinks(menuToHide);
		
		const menuToShow = el.nextElementSibling; //<ul>
		showSubMenu(menuToShow);
	},
	open = (e) => {
		/*if(e){
			e.stopImmediatePropagation();
	        	e.stopPropagation();
	        	e.preventDefault();
		}*/
		
		dropDown.classList.add("dropdown-is-active");
		dropDownTrigger.classList.add("dropdown-is-active");
		dropDown.classList.remove("hide");
		dropDown.setAttribute('aria-hidden', 'false');
		dropDownTrigger.setAttribute("aria-expanded", "true");
		document.body.style.overflow = "hidden";
		updateMenuLoad();
		linksBehind.Hide();	
		fadeInOverlay();
		updateFocusTrap(null, topMenu.children);
		document.addEventListener("keydown", closeOnEscape);
		setTimeout(() => { dropDownClose.focus(); }, 300);	
		return false;
	},
	close = (e) => {
		/*if(e){
			e.stopImmediatePropagation();
	        	e.stopPropagation();
	        	e.preventDefault();
		}*/
        	dropDown.classList.remove("dropdown-is-active");
		dropDownTrigger.classList.remove("dropdown-is-active");
		dropDown.setAttribute('aria-hidden', 'true');
		dropDownTrigger.setAttribute("aria-expanded", "false");
		document.body.removeAttribute("style");	
		topMenu.removeAttribute("style");
		linksBehind.Show();
		closeOpenMenus();
		fadeOutOverlay();
		dropDownTrigger.focus();
		updateFocusTrap(topMenu.children, null);
		document.removeEventListener("keydown", closeOnEscape);
		setTimeout(() => { dropDown.classList.add("hide"); }, 600);
		return false;
	},
	updateMenuLoad = () => {
		const menuLoad = document.getElementById("menuLoading");
		if(menuLoad) menuLoad.remove();
		
		const menuStatus = document.getElementById("menuLoadFinished");
		if(!menuStatus){ dropDown.insertAdjacentHTML("afterbegin", "<p id='menuLoadFinished' class='visually-hidden' role='alert' aria-atomic='true'>Main Menu has finished loading</p>"); }
	},
	closeOpenMenus = () => {
		// close all open menus to reset for next time menu is opened
		const activeSubs = document.querySelectorAll("ul.is-active");
		for (let i = 0; i < activeSubs.length; i++) {
			const btn = activeSubs[i].querySelector(".go-back").querySelector("button");
			closeOpenSubMenu(btn);
		}
	},
	fadeInOverlay = () => {
		menuOverlay.classList.add("animate", "animateFadeIn", "animateFaster");
		menuOverlay.classList.remove("animateFadeOut", "hide");
	},
	fadeOutOverlay = () => { 
		menuOverlay.classList.remove("animateFadeIn");
		menuOverlay.classList.add("animateFadeOut");
		setTimeout(() => { menuOverlay.classList.add("hide"); }, 400);
	},
	showSubMenu = (subMenu) => {	
    		if (!subMenu) return; // Check if subMenu is not null or undefined
    		
		subMenu.classList.remove('hide');
		topMenu.style.overflowY = "hidden";
		const previousSubMenu = subMenu.parentNode.parentNode;
		if(previousSubMenu.classList.contains("cd-secondary-dropdown")) previousSubMenu.style.overflowY = "hidden";

		setTimeout(() => {
			subMenu.classList.remove('is-hidden');
			subMenu.classList.add('is-active');
			subMenu.setAttribute('aria-hidden', 'false');
			updateFocusTrap(previousSubMenu.children, subMenu.children);
		}, 200);
		setTimeout(() => { subMenu.querySelector(".go-back").querySelector("button").focus(); }, 300);
	},
	closeOpenSubMenu = (backButton) => {
		const parent = backButton.parentNode; // <li>
		const activeMenu = parent.parentNode; // <ul>
		const hiddenMenu = activeMenu.parentNode.parentNode; // next parent <ul>				
		hiddenMenu.classList.remove('move-out');
		activeMenu.previousElementSibling.setAttribute("aria-expanded", "false");
		if(hiddenMenu.classList.contains("cd-secondary-dropdown")) hiddenMenu.removeAttribute("style");
		showPreviousLinks(hiddenMenu);	
		activeMenu.classList.add('is-hidden');
		activeMenu.classList.remove('is-active');
		activeMenu.setAttribute('aria-hidden', 'true');
		updateFocusTrap(activeMenu.children, hiddenMenu.children);
		setTimeout(() => { activeMenu.classList.add("hide"); }, 200);
	},
	updateFocusTrap = (removeChildren, addChildren) => {
		if(removeChildren !== null){
			let visibleChildren = [];
			for(let i = 0; i < removeChildren.length; i++){
				let isVisible = findVisible(removeChildren[i]);
				if(isVisible) visibleChildren.push(removeChildren[i]);
			}
			let lastListItem = visibleChildren[visibleChildren.length -1];
			if(lastListItem && lastListItem.children[0].tagName == "BUTTON"){
				lastListItem.querySelector("button").removeEventListener("keydown", trapFocus);
			}else if (lastListItem && lastListItem.querySelector("a")) {
	                    lastListItem.querySelector("a").removeEventListener("keydown", trapFocus);
	              }
			closeButton.RemoveKeyEvent(lastListItem);
		}
		if(addChildren !== null){
			let visibleChildren = [];
			for(let i = 0; i < addChildren.length; i++){
				let isVisible = findVisible(addChildren[i]);
				if(isVisible) visibleChildren.push(addChildren[i]);
			}
			let lastListItem = visibleChildren[visibleChildren.length -1];
			if(lastListItem.children[0].tagName == "BUTTON") lastListItem.querySelector("button").addEventListener("keydown", trapFocus);
			else lastListItem.querySelector("a").addEventListener("keydown", trapFocus);
			closeButton.AddKeyEvent(lastListItem);
		}
	},
	getLinkOrButton = (listItem) => {
		if (listItem.children[0].tagName == "BUTTON") return listItem.querySelector("button");
		else return listItem.querySelector("a");
	},
	hidePreviousLinks = (listParent) => {
		//remove dropdown links from tab order
		const dropdownList = [].slice.call(listParent.childNodes); // <li> elements
		for(let i = 0; i < dropdownList.length; i++){
    			//needs to get link OR button because the go-backs are buttons
    			if(dropdownList[i].nodeName !== "#text"){ // added for a/b test
				const actionElement = getLinkOrButton(dropdownList[i]);
				actionElement.setAttribute("tabindex", "-1");
				actionElement.setAttribute("aria-hidden", "true");
			}
		}
	},
	
	showPreviousLinks = (listParent) => {
		//add drpdown links back to tab order 
		const dropdownList = [].slice.call(listParent.childNodes); // <li> elements
		for(let i = 0; i < dropdownList.length; i++){
			if(dropdownList[i].nodeName !== "#text"){ // added for a/b test
	    			//needs to get link OR button because the go-backs are buttons
				const actionElement = getLinkOrButton(dropdownList[i]);
				actionElement.removeAttribute("tabindex");
				actionElement.removeAttribute("aria-hidden");
			}
		}
	},
	goBack = (el) => {
		closeOpenSubMenu(el);
		omnitureLog.LogClick(el);
		const subMenuTrigger = el.parentNode.parentNode.previousElementSibling;
		setTimeout(() => { subMenuTrigger.focus(); }, 200);
	},
	secondBackBtnEvent = () => {
		//e.preventDefault();
		topMenu.removeAttribute("style");
	},
	closeOnEscape = (e) => {
		if(e.keyCode == 27 && dropDown.classList.contains('dropdown-is-active')){
			close(e);
		}
	},
	trapFocus = (e) => {
		if (e.keyCode === 9 && e.shiftKey){
			e.preventDefault();
			e.stopPropagation();
			const link = e.currentTarget.parentNode.previousElementSibling;
			const previousLink = link.querySelector("a") ? link.querySelector("a") : link.querySelector("button");
			previousLink.focus();
		} else if (e.keyCode === 9) {
			e.preventDefault();
			e.stopPropagation();
			dropDownClose.focus();
	      	}
	},
	closeButton = (function(){
		let elementToFocus;
		let addKeyEvent = (element) => {
			elementToFocus = element;
			dropDownClose.addEventListener("keydown", closeBtnTabShift);	
		},
		removeKeyEvent = (element) => {
			elementToFocus = element;
			dropDownClose.removeEventListener("keydown", closeBtnTabShift);	
		},
		closeBtnTabShift = (e) =>{ 
			if (e.keyCode === 9 && e.shiftKey){
				e.preventDefault();
				e.stopPropagation();
				let element = elementToFocus.querySelector("a") ? elementToFocus.querySelector("a") : elementToFocus.querySelector("button");
				element.focus();
			}
		};
		return{
			AddKeyEvent: addKeyEvent,
			RemoveKeyEvent: removeKeyEvent
		};
	})(),
	
	// hide everything else behind the menu for safari voiceover swiping
	// this stops the swipe from going to any links behind the menu
	linksBehind = (function(){
		const els = [
			document.getElementById("mainContent"), 
			document.getElementById("iwc-chat-wrapper"), 
			document.getElementById("iwc-email-wrapper"), 
			document.getElementById("iwc-contactPanel"), 
			document.querySelector(".menuBar"), 
			menuTrigger, 
			document.getElementById("menuLoadFinished"), 
			document.querySelector("Footer"), 
			document.querySelector(".back-to-top")
		];
		let hide = () => {
			for(let i = 0; i < els.length; i++){
				if(els[i]) els[i].setAttribute("aria-hidden", "true");
			}
		},
		show = () => {
			for(let i = 0; i < els.length; i++){
				if(els[i]) els[i].removeAttribute("aria-hidden");
			}
		};
		return{
			Hide: hide,
			Show: show
		};
	})(),
	omnitureLog = (function(e){
		const account = typeof s_account === 'string' ? s_account : '4imprintprod';
		const s = typeof s_gi === 'function' ? s_gi(account) : '';		
		
		let logClick = (el) =>{
			const clickedElement = el;
			if(s !== ''){			
				// Molly added 3/28 as per mark's request to log the clicked element
				// only when exiting the menu and going to another page
				// this will also log all of it's parent elements that were clicked to get here
				if(!clickedElement.classList.contains("displayFlex")){
					const parentLinksClicked = findAllParentLinks(clickedElement);
					const clickedElementText = clickedElement.textContent.split("within")[0].trim();
					const logMenuClick = parentLinksClicked + clickedElementText;
					
					s.eVar39 = logMenuClick;	// per shawn angle, wanted eVar39 to work the same as prop39 4/12/24
					s.hier1 = logMenuClick;
					s.prop39 = logMenuClick; 
					s.linkTrackVars = "prop39,eVar39,hier1";
					s.linkTrackEvents = 'None';
					if(typeof s.tl === "function") s.tl(clickedElement,"o");
				} 
			}
		},
		findAllParentLinks = (el) => {
			let allLinks = document.getElementById("topOfMenu").querySelectorAll("a[aria-expanded='true'], button[aria-expanded='true']");
			let linksClicked = "";
			if(allLinks.length > 0){
				for(let i = 0; i < allLinks.length; i++){
					let linkText = allLinks[i].textContent.split("within")[0].trim();
					linksClicked += linkText + " | "; 
				}
			}
			return linksClicked;
		};
		
		return{
			LogClick: logClick
		};
	})();
	
	return{
		Init: init
	};
};
(function(){
	const menu4i = new megaMenu4i(); 
	menu4i.Init();
})();